import {mapGetters} from "vuex";


export default {
    computed:{
        ...mapGetters(['authUser']),

        isAdmin(){
            return this.authUser.id===1
        }
    }

}