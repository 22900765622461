/* eslint-disable */
export const FileService = {

    generateFileUrl(blob,file_name = 'NEWS',mime_type = 'application/pdf'){
        if(blob) {
            return window.URL.createObjectURL(new Blob([blob],{type:mime_type}));
        }
        return null;
    },

    viewFile(fileUrl = null) {
        if(fileUrl) {
            const link = document.createElement('a');
            link.setAttribute('href', fileUrl);
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
        }

    }

}