<template>
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <title>Privacy Policy</title>
    <meta name="description" content="K Line RoRo Bulk Ship Management Co., Ltd. Landing Page">
    <meta name="keywords" content="KRBS, Manila, Philippines">
    <meta name="author" content="Elite Software and Data Security Inc.">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="css/custom.css">
    <link rel="icon"
          type="image/png/ico"
          href="images/krbs_logo.ico">
  </head>
  <body>
  <header class="elt-my">
    <div class="elt-con">
      <div class="elt-row elt-py">
        <div class="elt-col-1">
          <a href="index"><img src="@/assets/krbs_logo.ico" alt="KRBS Logo" width="100%"></a>
        </div>
        <div class="elt-col-11 elt-v-center">
          <h1>"K" LINE RORO BULK SHIP MANAGEMENT CO., LTD.</h1>
        </div>
      </div>
      <div class="elt-row welcome" style="background-image: url(images/welcome_banner.png);">
        <div class="elt-col-7"></div>
        <div class="elt-col-5 elt-v-center">
          <h2 class="elt-tx-right elt-tx-white elt-p">PRIVACY POLICY</h2>
        </div>
      </div>
    </div>
  </header>
  <div class="elt-con elt-pb-1">
    <div class="elt-row">
      <div class="elt-col-12">
        <h3 class="h1-plus elt-pb">Elite Software and Data Security Inc.'s Privacy Policy</h3>
        <p class="privacy-policy elt-pt ">
          Your privacy is important to us. It is <b>Elite Software and Data Security Inc.'s</b> policy to respect your privacy regarding any information we may collect from you across the website,
          <a href="http://www.krbsgroup.com">http://www.krbsgroup.com</a>, and other sites we operate.
          <br /><br />
          We collect the following personal information from you when you manually or electronically submit to us your user account details such as name, position, email and/or birth date.
          <br /><br />
          We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
          <br /><br />
          The collected personal information is utilized solely for documentation, processing and/or verification purposes for the KRBS website and other sites we operate.
          <br /><br />
          We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
          <br /><br />
          We don’t share any personally identifying information publicly or with third-parties, except when required to by law or by you.
          <br /><br />
          Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
          <br /><br />
          You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
          <br /><br />
          Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
          <br /><br />
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
          <br /><br />
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at
          <a href="mailto: legal@elitesdsi.com">legal@elitesdsi.com</a>
        </p>
      </div>
    </div>
  </div>
  <footer class="elt-my elt-tx-center">
    <div class="elt-con elt-border-thick-t elt-py-3">
      <p class="elt-tx-light">
        Copyright © 2020 "K" Line RoRo Bulk Ship Management Co., Ltd. All rights reserved.
      </p>
      <p class="elt-pt"><router-link :to="{name:'home'}">Home</router-link></p>
    </div>
  </footer>
  </body>
  </html>
</template>

<script>
export default {
  name: "PrivacyView"
}
</script>

<style scoped>

</style>