/* eslint-disable */
import {axiosService} from "@/services/axios.service";
import {ROUTE_LISTS} from "@/config/constants.config";

const state = {
    users: [],
}

const mutations={
    SET_USERS: (state, users) => state.users = users,
}

const actions={
    async getUsers({commit}, params = null) {
        const response = await axiosService.consume('get', ROUTE_LISTS.user, params)
        if (response) {
            commit('SET_USERS', response?.data?.data ?? response?.data ??  null)
            commit('SET_PAGINATION', response?.data ??  null)
            return response
        }
    },
    async upsertUser({commit}, {params = null,id=null}) {
        let url = [ROUTE_LISTS.user].join('/')
        let method='post'
        if (id && !isNaN(id)) {
            method='put'
            url+=`/${id}`
        }
        const response = await axiosService.consume(method, url, params)
        if (response) {
            return response
        }
    },

    async deleteUser({commit}, params = null) {
        const url = [ROUTE_LISTS.user, params.id].join('/')
        const response = await axiosService.consume('delete', url)
        if (response) {
            return response?.data ?? response?.data?.data ?? null
        }
    },
}

const getters={
    users:(state)=>state.users
}


export default {state, mutations, actions, getters}