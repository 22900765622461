import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from "@/views/AdminView.vue";
import NewsComponent from "@/components/NewsComponent.vue";
import SiteComponent from "@/components/SiteComponent.vue";
import UserComponent from "@/components/UserComponent.vue";
import LoginView from "@/views/LoginView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      requiresAuth:false,
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta:{
      requiresAuth:false,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView,
  },
  {
    path: '/auth/login',
    name: 'login',
    component: LoginView,
    meta:{
      requiresAuth:false,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    redirect:'/admin/news',
    meta:{
      requiresAuth:true,
    },
    children:[
      {
        path: 'news/',
        name: 'news',
        component: NewsComponent,
      },
      {
        path: 'site/',
        name: 'sites',
        component: SiteComponent,
      },
      {
        path: 'user/',
        name: 'users',
        component: UserComponent,
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
   return { top: 0, left: 0 }
  },
  linkActiveClass:'active'
})

router.beforeEach((to,from,next)=>{
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('AUTH_TOKEN') != null) {
      return next();
    }
    return next({name:'login'});
  }

  if(to.matched.some(record => !record.meta.requiresAuth)) {
      if (localStorage.getItem('AUTH_TOKEN') != null && !['/','/privacy'].includes(to.fullPath) ) {
        return next({name:'admin'});
      }
    return next();
  }

})


export default router
