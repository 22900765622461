/* eslint-disable */
export default {

    methods:{
        createAlert(title= null,message=null,confirmText='Yes, proceed',cancelText='Cancel'){
            return this.$swal.fire({
                icon:'question',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                confirmButtonText:confirmText,
                cancelButtonText:cancelText,
                title:title ?? 'Confirm Creation',
                text:message ?? 'Do you want to save this new data?',
                showCancelButton:true,
                confirmButtonColor: '#03944c',
                cancelButtonColor: '#3f3f3f',
            }).then((result)=>{
                return result.isConfirmed
            });
        },

        successAlert(title='Action Succeeded',message='An action has been executed successfully',confirmText='Ok'){
            return this.$swal.fire({
                icon:'success',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                confirmButtonText:confirmText,
                title:title,
                text:message,
                showCancelButton:false,
                confirmButtonColor: '#03944c',
            }).then((result)=>{
                return result.isConfirmed
            });
        },

        updateAlert(title= null,message= null,confirmText='Yes, proceed',cancelText='Cancel'){
            return this.$swal.fire({
                icon:'question',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                confirmButtonText:confirmText,
                cancelButtonText:cancelText,
                title:title ?? 'Update Confirmation',
                text:message ?? 'Do you want to save the updated data?',
                showCancelButton:true,
                confirmButtonColor: '#03944c',
                cancelButtonColor: '#3f3f3f',
            }).then((result)=>{
                return result.isConfirmed
            });
        },

        errorAlert(message= {}){
            return this.$swal.fire({
                icon:'error',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                title:message?.title ?? 'Error on executing function',
                html:message.errorBag ?? 'Function not Executing properly: Error',
                showCancelButton:false,
                confirmButtonColor: '#da0d0d',
            })
        },

        deleteAlert(title='Delete Data',message='Do you want to delete from the list ?',confirmText='Yes, Proceed', cancelText = 'Cancel'){
            return this.$swal.fire({
                icon:'error',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                title:title,
                text:message,
                confirmButtonText:confirmText,
                cancelButtonText:cancelText,
                showCancelButton:true,
                confirmButtonColor: '#da0d0d',
            }).then((result)=>{
                return result.isConfirmed
            });
        },

        cancelAlert(title='Cancel Action',message='Do you want to cancel this action ?',confirmText='Yes', cancelText = 'No'){
            return this.$swal.fire({
                icon:'question',
                allowOutsideClick:false,
                allowEscapeKey:false,
                allowEnterKey:true,
                title:title,
                text:message,
                confirmButtonText:confirmText,
                cancelButtonText:cancelText,
                showCancelButton:true,
                confirmButtonColor: '#03944c',
            }).then((result)=>{
                return result.isConfirmed
            });
        },

    }

}