<template>
  <div class="container-fluid my-4">
    <h1> User Lists</h1>
    <div class="row mb-2">
      <div class="col-4">
        <form>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Search</span>
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchParams.search" aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </form>
      </div>
      <div class="col-8 text-end">
        <button class="btn btn-success mx-2"  data-bs-toggle="modal" data-bs-target="#userModal">Add User</button>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <pagination-component @changePage="setPage" ></pagination-component>
      </div>
    </div>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col" class="text-center" style="width: 1em">#</th>
        <th scope="col" class="text-center">LAST NAME</th>
        <th scope="col" class="text-center">FIRST NAME</th>
        <th scope="col" class="text-center">MIDDLE NAME</th>
        <th scope="col" class="text-center">EMAIL ADDRESS</th>
        <th scope="col" class="text-center">USERNAME</th>
        <th  scope="col" class="text-center" style="width: 1em"> ACTION</th>
      </tr>
      </thead>
      <tbody>
        <tr v-if="!users.length">
          <td colspan="100%" class="text-center text-danger fw-bolder h5 py-2"> --- NO RECORDS FOUND ---</td>
        </tr>
        <tr v-else v-for="(user,index) in users" :key="index">
          <td>{{pagination.from + index}}</td>
          <td class="text-center align-middle">
            {{user.last_name}}
          </td>
          <td class="text-center align-middle">
            {{user.first_name}}
          </td>
          <td class="text-center align-middle">
            {{user.middle_name}}
          </td>
          <td class="text-center align-middle">
            {{user.email}}
          </td>
          <td class="text-center align-middle">
            {{user.username}}
          </td>
          <td class="text-center align-middle">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button class="dropdown-item" type="button" @click="setFields(user)" data-bs-toggle="modal"
                          data-bs-target="#userModal">Update
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button" @click="confirmDeleteUser(user)">Delete</button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade" id="userModal" ref="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Last Name</span>
                <input type="text" v-model="userField.last_name" class="form-control" placeholder="Last Name" aria-label="Last Name" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">First Name</span>
                <input type="text" v-model="userField.first_name" class="form-control" placeholder="First Name" aria-label="First Name" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Middle Name</span>
                <input type="text" v-model="userField.middle_name" class="form-control" placeholder="Middle Name" aria-label="Middle Name" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Username</span>
                <input type="text" v-model="userField.username" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Email</span>
                <input type="email" v-model="userField.email" class="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Password</span>
                <input type="password" v-model="userField.password" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Confirm Password</span>
                <input type="password" v-model="userField.password_confirmation" class="form-control" placeholder="Confirm Password" aria-label="Confirm Password" aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" @click="saveUser" v-if="!Object.prototype.hasOwnProperty.call(userField,'id')"  class="btn btn-primary">Save User</button>
              <button type="button" @click="updateUser" v-else  class="btn btn-primary">Update User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import alertMixin from "@/mixins/alert.mixin";
import {ErrorService} from "@/services/error.service";
import PaginationComponent from "@/components/PaginationComponent.vue";
import modalMixin from "@/mixins/modal.mixin";
import formDataMixin from "@/mixins/formData.mixin";
import debounce from "lodash/debounce";

export default {
  name: "UserComponent",
  components: {PaginationComponent},
  data(){
    return{
      userField:{},
      searchParams:{
        perPage:10,
        page:1,
        search:"",
      }
    }
  },
  mixins:[alertMixin,modalMixin,formDataMixin],
  methods:{
    ...mapActions(['getUsers','upsertUser','deleteUser']),

    setFields(user){
      this.userField=Object.assign({},user)
    },

    async setPage(page = null){
      this.searchParams.page=page ?? 1;
      const params=Object.assign({},this.searchParams)
      await this.getUsers(this.generateFormParams(params))
    },

    async saveUser(){
      try {
        const confirmed = await this.createAlert();
        if(confirmed) {
          const response = await this.upsertUser({params:this.userField})
          if(response) {
            await this.successAlert('USER ADDED SUCCESSFULLY','A new User has been added successfully')
            this.setFields({})
            await this.setPage(this.pagination.current_page)
            this.closeModal('userModal')
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'USER')
        await this.errorAlert(message);
      }
    },
    async updateUser(){
      try {
        const confirmed = await this.updateAlert();
        if(confirmed) {
          const response = await this.upsertUser({params:this.userField,id:this.userField.id})
          if(response) {
            await this.successAlert('USER UPDATED SUCCESSFULLY','A User has been updated successfully')
            this.setFields({})
            await this.setPage(this.pagination.current_page)
            this.closeModal('userModal')
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'USER')
        await this.errorAlert(message);
      }
    },

    async confirmDeleteUser(user){
      try {
        const confirmed = await this.deleteAlert('Delete User','Do you want to remove this user account from the list ?');
        if(confirmed) {
          const response = await this.deleteUser(user)
          if(response) {
            await this.successAlert('USER DELETED!','A User has been deleted successfully')
            this.setFields({})
            await this.setPage(this.pagination.current_page)
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'USER')
        await this.errorAlert(message);
      }
    },
  },
  computed:{
    ...mapGetters(['users','pagination'])
  },
  async created() {
    this.searchUserList=debounce(()=>{
      this.setPage()
    },500)
    await this.setPage(1)
  },
  watch:{
    'searchParams.search'(){
      this.searchUserList()
    }
  }

}
</script>

<style scoped>

</style>