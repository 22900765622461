import {axiosService} from "@/services/axios.service";
import {ROUTE_LISTS} from "@/config/constants.config";

const state={
    authUser:{},
}

const mutations={
    SET_USER:(state,user)=>state.authUser=user
}

const actions={

    async authorizeUser({commit},params){
        const url = [ROUTE_LISTS.auth,'login'].join('/')
        const response = await axiosService.consume('post',url,params)
        if (response) {
            commit('SET_USER', response.data.user)
            localStorage.setItem('AUTH_TOKEN',response.data.token)
            localStorage.setItem('AUTH_USER',JSON.stringify(response.data.user))
            return response
        }
    },

    async getAuthUser({commit}) {
        const url = [ROUTE_LISTS.auth,'user'].join('/')
        const response = await axiosService.consume('get',url)
        if (response) {
            commit('SET_USER', response.data)
            return response
        }
    },

    async logoutUser({commit}){
        const url = [ROUTE_LISTS.auth,'logout'].join('/')
        const response = await axiosService.consume('post',url)
        if (response) {
            commit('SET_USER', {})
            localStorage.removeItem('AUTH_TOKEN')
            localStorage.removeItem('AUTH_USER')
            return response
        }
    }

}

const getters={
    authUser:(state)=>state.authUser
}

export default {state,mutations,actions,getters}