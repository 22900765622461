/* eslint-disable */
export const ErrorService = {

    generateErrorMessage (error, entity=null) {
        const errorStatus= error?.response?.status ?? 200

        const errorTitle= this.getResponseCodeMessage(errorStatus)

        const errors= error.response?.data?.errors  ?? null;


        const message= error.response?.data?.message  ?? null;

        let errorBag='';

        if(errors){
            if(typeof errors == 'object'){
                errorBag+='<ul class="list-unstyled" style="list-style: none">';
                Object.keys(errors).forEach((key)=>{
                    errorBag+=`<li class="text-danger fw-bold">${Array.isArray(errors[key]) ? errors[key].join('br') : errors[key]}</li>`
                })
                errorBag+='</ul>';
            }

            if(Array.isArray(errors)){
                errorBag+='<ul>';
                Array.keys(errors).forEach((key)=>{
                    errorBag+=`<li>${Array.isArray(errors[key]) ? error[key].join('br') : error[key]}</li>`
                })
                errorBag+='</ul>';
            }
        }

        else {
            errorBag = message
        }


        return {title:errorTitle,message:message,errorBag:errorBag}

    },

    getResponseCodeMessage(errorCode = 200){
        let title='';

        switch (errorCode){
            case 200:
                title = 'Ok'
                break;

            case 201:
                title = 'Accepted'
                break;

            case 204:
                title = 'No Content'
                break;

            case 400:
                title = 'Bad Request'
                break;

            case 401:
                title = 'Unauthorized'
                break;

            case 403:
                title = 'Forbidden'
                break;

            case 404:
                title = 'Not Found'
                break;

            case 405:
                title = 'Method Not Allowed'
                break;

            case 414:
                title = 'URI Too Long'
                break;

            case 422:
                title = 'Unprocessable Content'
                break;

            case 500:
                title = 'Internal Server Error'
                break;

            case 502:
                title = 'Bad Gateway'
                break;

            case 504:
                title = 'Gateway Timeout'
                break;
        }

        return title;
    }




}