<template>
  <nav class="navbar navbar-expand-lg bg-danger text-white">
    <div class="container-fluid">
      <router-link class="navbar-brand text-white fw-bold" :to="{name:'news'}">KRBS Website Admin</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item mx-3">
            <router-link :to="{'name':'news'}" class="nav-link text-white" active-class="active">NEWS</router-link>
          </li>
          <li class="nav-item mx-3">
            <router-link :to="{'name':'sites'}" class="nav-link text-white" active-class="active">SITE</router-link>
          </li>
          <li class="nav-item mx-3" v-if="isAdmin">
            <router-link :to="{'name':'users'}" class="nav-link text-white" active-class="active">USER</router-link>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="btn text-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ !!authUser ? authUser.full_name : 'Anonymous' }}
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item text-center" href="#" @click="execLogoutUser">Logout</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>

</style>

<script>
import {mapActions, mapGetters} from "vuex";
import {ErrorService} from "@/services/error.service";
import GateMixin from "@/mixins/gate.mixin";

export default {
  name: "NavComponent",
  computed:{
    ...mapGetters(['authUser']),
  },
  mixins:[GateMixin],
  methods:{
    ...mapActions(['logoutUser']),

    async execLogoutUser(){
      try{
        const response=await this.logoutUser()
        if(response){
          this.$router.push({name:'login'})
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'USER')
        await this.errorAlert(message);
      }

    }
  }

}
</script>
