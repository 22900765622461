/* eslint-disable */
import {axiosService} from "@/services/axios.service";
import {ROUTE_LISTS} from '@/config/constants.config'
import {urlService} from "@/services/url.service";

const state = {
    news: [],
    newsToSort:[]
}

const mutations = {
    SET_NEWS: (state, news) => state.news = news,
    DELETE_NEWS: (state, newsId) => {
        const index = state.news.findIndex(news => news.id === newsId)
        if (index > 0) {
            state.news.splice(index, 1)
        }
    },
    SET_NEWS_TO_SORT:(state,newsToSort)=>state.newsToSort=newsToSort
}

const actions = {
    async getNews({commit}, params = null) {
        const response = await axiosService.consume('get', ROUTE_LISTS.news,params)
        if (response) {
            commit('SET_NEWS', response?.data?.data ?? response?.data ?? null)
            commit('SET_PAGINATION',response.data)
            return response
        }
    },
    async upsertNews({commit}, {params = null,id=null}) {
        let url = [ROUTE_LISTS.news].join('/')
        if (id && !isNaN(id)) {
            url += `/${id}/update`;
        }
        const response = await axiosService.consume('post', url, params)
        if (response) {
            return response
        }
    },

    async deleteNews({commit}, params = null) {
        const url = [ROUTE_LISTS.news, params.id].join('/')
        const response = await axiosService.consume('delete', url)
        if (response) {
            commit('DELETE_NEWS', response?.data ?? response?.data?.data ?? null)
            return response?.data ?? response?.data?.data ?? null
        }
    },


    async getNewsFile({commit},params = null){
        const url = [ROUTE_LISTS.news, params.id].join('/')
        const response = await axiosService.consume('get', url, null,{responseType: 'blob'})
        if(response) {
           return response?.data ?? false;
        }
        return false;
    },

    async sortNewsList({commit},params = null){
        const url = [ROUTE_LISTS.news, 'sort'].join('/')
        const response = await axiosService.consume('post', url, params)
        if(response) {
            return response?.data ?? false;
        }
        return false;
    },

    async getNewsToSort({commit},params = null){
        const response = await axiosService.consume('get', ROUTE_LISTS.news,params)
        if(response){
            commit('SET_NEWS_TO_SORT',  response?.data ?? null)
            return response?.data ?? false;
        }

        return false;

    }
}

const getters = {
    news: state => state.news,
    newsToSort:state=>state.newsToSort
}

export default {state, mutations, actions, getters}

