<template>
  <nav>
    <router-link to="/"></router-link>
    <router-link to="/"></router-link>
  </nav>
  <router-view/>
</template>

<style>
#app {
}

@import './css/custom.css';
@import './css/fontawesome/css/all.css';








</style>

