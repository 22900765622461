<template>
  <div class="container-fluid bg-light" style="height: 100vh;">
    <div class="row justify-content-center align-items-center" style="height: 100%">
      <div class="col-4">
        <div class="card p-2">
          <div class="card-title py-2">
            <h2 class="text-center text-danger fw-bolder"> KRBS WEBSITE - CONTENT MANAGEMENT ADMIN </h2>
          </div>
          <form @submit.prevent="login">
            <div class="card-body">
              <div class="input-group flex-nowrap">
                <span class="input-group-text bg-danger text-white" id="addon-wrapping">Username</span>
                <input type="text" class="form-control" v-model="userField.username" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping">
              </div>
              <div class="input-group flex-nowrap mt-2">
                <span class="input-group-text bg-danger text-white" id="addon-wrapping">Password</span>
                <input type="password" class="form-control" v-model="userField.password" placeholder="Password" aria-label="Password" aria-describedby="addon-wrapping">
              </div>
              <div class="d-grid gap-2 mt-4">
                <button class="btn btn-danger" type="submit">Login</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import AlertMixin from "@/mixins/alert.mixin";
import {ErrorService} from "@/services/error.service";

export default {
  name: "LoginView",
  data(){
    return{
      userField:{
        username:'',
        password:''
      }
    }
  },
  mixins:[AlertMixin],
  methods:{
    ...mapActions(['authorizeUser']),

    async login(){
      try {
        const response = await this.authorizeUser(this.userField);
        if(response){
          this.$router.push({name:'admin'});
        }
      }
      catch (error) {
        const errors= ErrorService.generateErrorMessage(error)
        this.errorAlert(errors)
      }

    }

  },
}
</script>

<style scoped>

</style>