export const urlService = {

    queryString(params) {
        if(params) {
            const arr=[];
            Object.entries(params)
                .forEach(entry => {
                    const [key, value] = entry;
                    if(typeof value !== 'undefined') arr.push(`${key}=${value}`)
                })
            const queryString=arr.join('&')
            return queryString ? `?${queryString}` : '?';
        }
        return null;
    }

}