import {urlService} from "@/services/url.service";

export default {

    methods:{

        generateFormData(data,exceptedKeys=[]){
            let formData=new FormData();
            Object.keys(data).forEach(key=>{
                if(!exceptedKeys.includes(key)){
                    formData.append(key,data[key])
                }
            })
            return formData;
        },

        generateFormQueryString(data){
            return urlService.queryString(data)
        },

        generateFormParams(data) {
           Object.keys(data).forEach((item)=>{
                if(data[item] == null || typeof data[item] == 'undefined'){
                    delete data[item]
                }
            })
           return data;
        }


    }

}