import axios from "axios";
import {API_URL} from "@/config/constants.config";
import router from "@/router";
export default() => {
    axios.defaults.baseURL = API_URL
    axios.defaults.withCredentials=false
    axios.interceptors.request.use((config) => {
        let accessToken = localStorage.getItem('AUTH_TOKEN') ?? null
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;

        }
        config.headers['Cache-Control'] = `no-cache`;
        config.headers['Content-Type'] = `application/x-www-form-urlencoded`;
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    axios.interceptors.response.use(undefined, (error) => {
        const config = error.config
        if (error.response.status === 503) {
            localStorage.removeItem('AUTH_USER')
            localStorage.removeItem('AUTH_TOKEN')
            router.push({name: 'MaintenanceMode'})
        }
        if (error.response.status === 401 && !config._retry) {
            config._retry = true
            localStorage.removeItem('AUTH_USER')
            localStorage.removeItem('AUTH_TOKEN')
            delete axios.defaults.headers.common.Authorization
            if (router.currentRoute.name !== 'login') {
                router.push({name: 'login'})
            }
        }
        return Promise.reject(error);
    })
    // axios.interceptors.request.use((response)=>{
    //     return Promise.resolve(response);
    // },(error) => {
    //     return Promise.reject(error);
    // })
}