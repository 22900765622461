<template>
  <div>
    <ul class="list-group">
      <li class="list-group-item" draggable="true" @drop="dropItem($event,item)"
          v-on:dragover.prevent v-on:dragenter.prevent v-for="(item,index) in itemList"
          @dragstart="dragItem($event,item)" :key="item.id"><span class="fw-bolder h5">#{{index + 1}}</span> {{item.title}}</li>
    </ul>
    <hr>
    <div class="text-right mt-2">
      <button class="btn btn-success" @click="implementSort">Save Changes</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "DraggableListComponent",
  props: {
    items:{
      type:[Object,Array],
      required:true
    },
  },
  data(){
    return {
      currentItem:{},
      itemList:{},
    }
  },
  methods:{
    dragItem(evt,item){
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      item=Object.assign({},item)
      this.currentItem=Object.assign({},item)
    },
    dropItem(evt,item){
      const draggedItem=Object.assign({},this.currentItem)
      const droppedItem=Object.assign({},item)
      const draggedItemIndex=this.itemList.map((item)=>item.id).indexOf(draggedItem.id)
      const droppedItemIndex=this.itemList.map((item)=>item.id).indexOf(droppedItem.id)
      this.itemList[droppedItemIndex]=draggedItem
      this.itemList[draggedItemIndex]=droppedItem
    },
    implementSort(){
      this.$emit('implSort',this.itemList.map(item=>item.id))
    }
  },
  watch:{
    items:{
      immediate: true,
      handler(newVal,oldVal){
        this.itemList=[...newVal]
      }
    }
  }
}
</script>

<style scoped>

</style>