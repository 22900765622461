<template>
  <div>
    <div class="row justify-content-center" style="height: 100vh;width: 100%">
      <div class="col-10 text-center align-self-center">
        <h1>404 | NOT FOUND</h1>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "NotFoundView",
}
</script>

<style scoped>

</style>