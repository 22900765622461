const state= {
    pagination : {},
}

const mutations= {
    SET_PAGINATION:(state,pagination)=>state.pagination=Object.assign({},pagination)
}

const actions= {
}

const getters= {
    pagination: (state)=>state.pagination,
}

export default {state,mutations,actions,getters}