<template>
  <nav aria-label="...">
    <ul class="pagination">
      <li class="page-item" :class="{'disabled':pagination.current_page===1}" @click="$emit('changePage',1)">
        <span class="page-link">FIRST</span>
      </li>
      <li class="page-item" v-for="num in paginationPages()" :class="{'active':pagination.current_page===num}" :key="num">
        <a class="page-link" href="#"  @click="$emit('changePage',num)">{{num}}</a>
      </li>
      <li class="page-item" :class="{'disabled':pagination.current_page===pagination.last_page}">
        <a class="page-link" href="#" @click="$emit('changePage',pagination.last_page)">LAST</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PaginationComponent",
  computed:{
    ...mapGetters(['pagination'])
  },
  methods:{
    paginationPages() {
      let pages = [];
      let currentPage = this.pagination.current_page;
      let lastPage = this.pagination.last_page;
      if(lastPage > 1) {
        let initialLimit = 1;
        if(currentPage + 5 > lastPage) {
          initialLimit = lastPage - 10;
        } else if(currentPage > 6) {
          initialLimit = currentPage - 5
        }
        initialLimit = initialLimit < 1 ? 1 : initialLimit;
        for (let i=initialLimit; i<=initialLimit + 10; i++) {
          pages.push(i);
          if(i>=lastPage) {
            break;
          }
        }
      }
      return  pages;
    },
  }
}
</script>

<style scoped>

</style>