import { createStore } from 'vuex'
import newsModule from "@/store/news.module";
import siteModule from "@/store/site.module";
import userModule from "@/store/user.module";
import authModule from "@/store/auth.module";
import paginationModule from "@/store/pagination.module";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    newsModule,
    siteModule,
    userModule,
    authModule,
    paginationModule
  }
})
