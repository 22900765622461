<template>
  <div class="container-fluid my-4">
    <h1> News Lists</h1>
    <div class="row mb-3 align-items-center">
      <div class="col-4">
          <form @submit.prevent="setPage">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">Search</span>
              <input type="text"  class="form-control" placeholder="Search Here" v-model="searchParams.search" aria-label="Username" aria-describedby="basic-addon1">
            </div>
          </form>
      </div>
      <div class="col-4">
        <form>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">STATUS</span>
            <select name="status" id="status" class="form-control" v-model="searchParams.active" @change="setPage(1)">
              <option class="text-center" :value="null">--- ALL STATUSES ---</option>
              <option class="text-center" :value="1">ACTIVE</option>
              <option class="text-center" :value="0">INACTIVE</option>
            </select>
          </div>
        </form>
      </div>
      <div class="col-4 text-end">
        <button class="btn btn-success mx-2" @click="setFields({})" data-bs-toggle="modal" data-bs-target="#newsModal">Add News</button>
        <button class="btn btn-info" @click="getNewsToSort({active:1})"  data-bs-toggle="modal" data-bs-target="#sortModal">Sort News</button>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <pagination-component @changePage="setPage" ></pagination-component>
      </div>
    </div>
    <div style="height: 70vh">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col" class="text-center" style="width: 2%">#</th>
          <th scope="col" class="text-center" style="width: 40%">TITLE</th>
          <th scope="col" class="text-center" style="width: 5%">ISSUED DATE</th>
          <th scope="col" class="text-center" style="width: 10%">STATUS</th>
          <th scope="col" class="text-center" style="width: 10%">IMPORTANT NEWS ?</th>
          <th scope="col" class="text-center" style="width: 8%">CREATED BY</th>
          <th scope="col" class="text-center" style="width: 2%">ACTION</th>
        </tr>
        </thead>
        <tbody>
          <tr v-if="!news.length">
            <td colspan="100%" class="text-center text-danger fw-bolder h5 py-2"> --- NO RECORDS FOUND ---</td>
          </tr>
          <tr v-for="(news,index) in news" :key="index">
          <td>{{pagination.from + index}}</td>
          <td class="text-center align-middle">
            <template v-if="news.hasFile">
              <a href="#" @click="viewFile(getNewsFile,news)">{{news.title}}</a>
            </template>
            <template v-else>
              {{news.title}}
            </template>
          </td>
          <td class="text-center align-middle">{{news.month}} {{news.year}}</td>
          <td :class="{
            'bg-success text-white' : news.active,
            'bg-danger text-white' : !news.active,
            }" class="text-center align-middle">{{news.active ? 'ACTIVE' : 'INACTIVE'}}</td>
          <td :class="{
            'bg-success text-white' : news.important,
            'bg-danger text-white' : !news.important,
            }" class="text-center align-middle">{{news.important ? 'YES' : 'NO'}}</td>
           <td class="align-middle text-center">{{news.createdBy}}</td>
          <td class="text-center align-middle">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                ACTION
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button class="dropdown-item" type="button" @click="setFields(news)" data-bs-toggle="modal"
                          data-bs-target="#newsModal">UPDATE
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button" @click="markNews(news,!news.important)">MARK AS {{news.important ? 'UNIMPORTANT' : 'IMPORTANT'}}</button>
                </li>
                <li>
                  <button class="dropdown-item" type="button" @click="confirmDeleteNews(news)">DELETE</button>
                </li>
              </ul>
            </div>
          </td>
        </tr>

        </tbody>
      </table>
    </div>


    <div class="modal fade" id="newsModal"  ref="newsModal"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header">
            <button type="button" class="btn-close"  aria-label="Close" @click.prevent="cancelModal('newsModal')"></button>
          </div>
          <form>
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Title</span>
                <input type="text" v-model="newsField.title" class="form-control" placeholder="News Title" aria-label="Site Title" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Issued Date</span>
                <input type="date" v-model="newsField.date" class="form-control" placeholder="Issued Date" aria-label="Site Description" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <label class="input-group-text" for="inputGroupSelect01">Important News ? </label>
                <select class="form-select" id="inputGroupSelect01" v-model="newsField.important">
                  <option :value="1">Yes</option>
                  <option :value="0">No</option>
                </select>
              </div>
              <div class="input-group mb-3">
                <label class="input-group-text" for="inputGroupSelect01">Status</label>
                <select class="form-select" id="inputGroupSelect01" v-model="newsField.active">
                  <option :value="1">Active</option>
                  <option :value="0">Inactive</option>
                </select>
              </div>
              <div class="input-group mb-3">
                <input type="file" ref="newsFile" class="form-control" accept="application/pdf">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" @click="saveNews" v-if="!Object.prototype.hasOwnProperty.call(newsField,'id')"  class="btn btn-primary">Save News</button>
              <button type="button" @click="updateNews" v-else  class="btn btn-primary">Update News</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="sortModal"  ref="sortModal" tabindex="-1" aria-labelledby="sortModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header">
            <h5 class="modal-title">Sort News</h5>
            <button type="button" class="btn-close" @click.prevent="cancelModal('sortModal')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <draggable-list-component v-model:items="newsToSort" v-if="newsToSort" @implSort="updateSort" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {mapActions, mapGetters} from "vuex";
import alertMixin from "@/mixins/alert.mixin";
import formDataMixin from "@/mixins/formData.mixin";
import fileMixin from "@/mixins/file.mixin";
import DraggableListComponent from "@/components/DraggableListComponent.vue";
import {ErrorService} from "@/services/error.service";
import PaginationComponent from "@/components/PaginationComponent.vue";
import modalMixin from "@/mixins/modal.mixin";
import debounce from "lodash/debounce"

export default {
  name: "NewsComponent",
  data(){
    return {
      newsField:{
        important:1,
        active:1
      },
      itemList:[],
      searchParams:{
        perPage:10,
        page:1,
        active:null
      }
    }
  },
  mixins:[alertMixin,formDataMixin,fileMixin,modalMixin],
  components:{PaginationComponent, DraggableListComponent},
  computed:{
    ...mapGetters(['news','newsToSort','pagination'])
  },
  methods:{
    ...mapActions(['getNews','getNewsToSort','upsertNews','deleteNews','getNewsFile','sortNewsList']),
    setFields(news = null){
      this.newsField=Object.assign({},news);
    },
    async setPage(page = null){
      this.searchParams.page=page ?? 1;
      const params=Object.assign({},this.searchParams)
      await this.getNews(this.generateFormParams(params))
    },
    async saveNews(){
      try {
        const fileCount = this.$refs.newsFile?.files?.length ?? 0;

        const withoutFileMessage=!fileCount ? 'Do you want to add news without its attachment ?' : null;

        const confirmed = await this.createAlert(null,withoutFileMessage);

        if(confirmed) {
          const formData=this.generateFormData(this.newsField)

          if(fileCount) {
             formData.append('attachment',this.$refs.newsFile.files[0])
          }

          const response = await this.upsertNews({params:formData,id:null})
          if(response) {
            await this.successAlert('NEWS ADDED SUCCESSFULLY','A news has been added successfully')
            await this.setPage(this.pagination.current_page)
            this.$refs.newsFile.value=null
            this.closeModal('newsModal')
            this.setFields(null);
          }
        }
      }
      catch (error){
       const message = ErrorService.generateErrorMessage(error,'NEWS')
       await this.errorAlert(message);
      }
    },

    async updateNews(){
      try {
        const confirmed = await this.updateAlert();
        if(confirmed) {
          const formData=this.generateFormData(this.newsField)
          if(this.$refs.newsFile.files && this.$refs.newsFile.files.length) {
            formData.append('attachment',this.$refs.newsFile.files[0])
          }
          const response = await this.upsertNews({params:formData,id:this.newsField.id})
          if(response) {
            this.$refs.newsFile.value=null
            await this.successAlert('NEWS UPDATE SUCCESSFULLY','A news has been updated successfully')
            await this.setPage(this.pagination.current_page)
            this.closeModal('newsModal')
            this.setFields(null);
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'NEWS')
        await this.errorAlert(message);
      }
    },

    async confirmDeleteNews(news){
      try {
        const confirmed = await this.deleteAlert('Delete News','Do you want to remove this news from the list ?');
        if(confirmed) {
          const response = await this.deleteNews(news)
          if(response) {
            await this.successAlert('NEWS DELETED','A news has been deleted successfully')
            await this.setPage(this.pagination.current_page)
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'NEWS')
        await this.errorAlert(message);
      }
    },

    async updateSort(ids){
      try {
        const confirmed = await this.updateAlert('Confirm Sorting','Do you want to save the new sorting list?');
        if(confirmed){
          const params={ids:ids}
          const response = await this.sortNewsList(params)
          if(response) {
            await this.successAlert('NEWS SORTED SUCCESSFULLY','News list has been sorted successfully')
            await this.setPage(this.pagination.current_page)
            this.closeModal('sortModal')
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'NEWS')
        await this.errorAlert(message);
      }
    },
    async markNews(news,important=1){
      try {
        const confirmed = await this.updateAlert();
        if(confirmed) {
          news.important=important ? 1 : 0
          const response = await this.upsertNews({params:news,id:news.id})
          if(response) {
            await this.successAlert('MARK UPDATED','A news has been updated successfully')
            await this.setPage(this.pagination.current_page)
            this.setFields(null);
            this.$refs.newsFile.value=null
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'NEWS')
        await this.errorAlert(message);
      }
    },

  },
  created() {
    this.searchNewsList=debounce(()=>{
      this.setPage()
    },500)
    this.setPage()
  },

  watch:{
    'searchParams.search'(){
      this.searchNewsList()
    }
  }
}
</script>

<style scoped>

</style>