/* eslint-disable */
import {axiosService} from "@/services/axios.service";
import {ROUTE_LISTS} from '@/config/constants.config'
import axios from "axios";

const state = {
    sites: [],
    sitesToBeSorted:[],
}

const mutations = {
    SET_SITE: (state, sites) => state.sites = sites,
    APPEND_SITE: (state, upsertSite) => {
        if(upsertSite){
            const index = state.sites.findIndex(site => site.id === upsertSite.id)
            if (index > 0) {
                state.sites.splice(index, 1, upsertSite)
            } else {
                state.sites.push(upsertSite)
            }
        }
    },
    DELETE_SITE: (state, siteId) => {
        const index = state.sites.findIndex(site => site.id === siteId)
        if (index > 0) {
            state.sites.splice(index, 1)
        }
    },
    SET_SITE_TO_BE_SORTED: (state, sitesToBeSorted) => state.sitesToBeSorted = sitesToBeSorted,
}

const actions = {
    async getSites({commit}, params = null) {
        const response = await axiosService.consume('get', ROUTE_LISTS.site, params)
        if (response) {
            commit('SET_SITE', response?.data?.data ?? response?.data ?? null)
            commit('SET_PAGINATION',response.data)
            return response
        }

    },
    async upsertSites({commit}, {params = null,id=null}) {
        const method = Object.prototype.hasOwnProperty.call(params, 'id') && !isNaN(params.id) ? 'put' : 'post';
        let url = [ROUTE_LISTS.site].join('/')
        if (id && !isNaN(params.id)) {
            url += `/${params.id}`;
        }
        const response = await axiosService.consume(method, url, params)
        if (response) {
            return response
        }
    },

    async deleteSite({commit}, params = null) {

        const url = [ROUTE_LISTS.site].join('/')
        const response = await axiosService.consume('delete', url, params)
        if (response) {
            commit('DELETE_SITE', params.id)
            return response.data
        }
    },

    async sortSiteLists({commit}, params = null) {
        const url = [ROUTE_LISTS.site, 'sort'].join('/')
        const response = await axiosService.consume('post', url, params)
        if (response) {
            return response?.data ?? false;
        }
    },

    async getSitesToBeSorted({commit}, params = null) {
        const response = await axiosService.consume('get', ROUTE_LISTS.site)
        if (response) {
            commit('SET_SITE_TO_BE_SORTED', response?.data ?? false)
            return response?.data ?? false;
        }
    }
}

const getters = {
    sites: state => state.sites,
    sitesToBeSorted:state=>state.sitesToBeSorted
}

export default {state, mutations, actions, getters}

