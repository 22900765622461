import axios from "axios";
import {urlService} from "@/services/url.service";
export const axiosService= {
    consume(method='get',url,params=null,config=null,idKey='id'){
        method=method.toLowerCase()
        const queryString=params ? urlService.queryString(params) : ''
        if(['get','post','put','patch','delete'].includes(method)){
            if(method === 'get'){
                if(queryString!==null)
                return axios[method](url + queryString,config);
            }
            if(method === 'delete'){
                let lookUp = '';
                if (params && Object.prototype.hasOwnProperty.call(params, idKey)) {
                    lookUp =`/${params[idKey]}`;
                }
                return axios.delete(url + lookUp, config);
            }
            return axios[method](url, params, config);
        }
    },
}