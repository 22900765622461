<template>
  <div>
    <NavComponent></NavComponent>
    <router-view></router-view>
  </div>

</template>

<style scoped>

</style>
<script>
import NavComponent from "@/components/NavComponent.vue";
import {mapActions} from "vuex";
export default {
  components:{NavComponent},
  methods:{
    ...mapActions(['getAuthUser'])
  },
  created(){
    this.getAuthUser()
  }
}

</script>
