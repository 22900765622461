<template>
    <header class="elt-my">
    <div class="elt-con">
      <div class="elt-row elt-py">
        <div class="elt-col-1">
          <img src="@/assets/krbs_logo.ico" alt="KRBS Logo" width="100%"/>
        </div>

        <div class="elt-col-11 elt-v-center">
          <h1>"K" LINE RORO BULK SHIP MANAGEMENT CO., LTD.</h1>
        </div>
      </div>

      <div class="elt-row welcome" style="background-image: url(images/welcome_banner.png);">

        <div class="elt-col-7"></div>
        <div class="elt-col-5 elt-v-center">
          <h2 class="elt-tx-right elt-tx-white elt-p">WELCOME TO KRBS</h2>
        </div>
      </div>
    </div>
  </header>

    <div class="elt-con" style="min-height: 90vh">
      <div class="elt-row">
      <div class="elt-col-7-xxl elt-col-7-xl elt-col-7-lg elt-pr elt-pb-3">
        <h3 class="h1-plus elt-pb">KRBS NEWS</h3>
        <button id="floating_button" ref="floating_button" class="elt-float-button elt-tx-white"
                title="KRBS Crewing and Training Manager" @click.prevent="show_sidebar">
          KRBS Crewing and Training Manager ➡
        </button>

        <!-- START OF LEFT SIDE -->
        <div class="elt-row file-holder" :class="{'elt-bg-4th':newsItem.important}" v-for="newsItem in news"
             :key="newsItem.id">
          <div class="elt-col-2">
            <div class="date-holder">
              <hr stay/>
              {{ newsItem.month }} <br/>{{ newsItem.year }}
            </div>
          </div>
          <div class="elt-col-10 elt-v-center">
            <template v-if="newsItem.hasFile">
              <p class="elt-tx-link" @click="viewFile(getNewsFile,newsItem)">{{ newsItem.title }} <span
                  v-if="newsItem.important" class="text-danger">** Important</span></p>
            </template>
            <template v-else>
              <p>{{ newsItem.title }} <span v-if="newsItem.important" class="text-danger">** Important</span></p>
            </template>

          </div>
          <!--              <div class="elt-col-10 elt-v-center">-->
          <!--                <p class="elt-tx-link" @click="getFile(newsItem)">{{ newsItem.title }}</p>-->
          <!--                <div v-for="newsAttachItem in newsattach" :key="newsAttachItem.id" style="display: inline-block;">-->
          <!--                  <div v-if="newsAttachItem.news_id == newsItem.id" @click="fetchFileAttach(newsAttachItem.id)" style = "padding-right: 5px; padding-bottom: 5px;  ">-->
          <!--                    <button class="btn badge text-bg-danger py-1">-->
          <!--                    <LogoSVG />{{ newsAttachItem.name }}-->
          <!--                    </button>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
        </div>
      </div>

      <!-- START OF RIGHT SIDE -->
      <div id="sidebar" ref="sidebar"
           class="elt-col-5-xxl elt-col-5-xl elt-col-5-lg elt-col-12-md elt-col-12-sm elt-tx-right">
        <i @click="close_sidebar" ref="close_sidebar" id="close_sidebar" class="fas fa-times elt-px"></i>
        <h3 class="h1-plus elt-pb elt-px">KRBS Crewing and Training Manager</h3>
        <div class="card-link" v-for="site in sites" :key="site.id" @click="openUrl(site.url)">
          <h4 style="margin-bottom: 5px" v-html="site.title"></h4>
          <p class="elt-tx-link">{{ site.description }}</p>
        </div>
      </div>
    </div>
    </div>

    <footer class="elt-my elt-tx-center">
      <div class="elt-con elt-border-thick-t elt-py-3 elt-py-sm-2">
        <p class="elt-tx-light">
          Copyright © 2020 "K" Line RoRo Bulk Ship Management Co., Ltd. All rights reserved.
        </p>
        <p class="elt-pt"><router-link :to="{name:'privacy'}">Privacy Policy</router-link></p>
      </div>
    </footer>
</template>


<script>

/* eslint-disable */
import LogoSVG from '@/css/fontawesome/svgs/regular/file-pdf.svg';
import {mapActions, mapGetters} from "vuex";
import fileMixin from "@/mixins/file.mixin";
// let sidebar = document.getElementById('sidebar');
let floating_button = document.getElementById('floating_button');

export default {

  data() {
    return {};
  },

  mixins: [fileMixin],

  computed: {
    ...mapGetters(['news', 'sites'])
  },

  components: {LogoSVG},

  async created() {
    await this.getSites()
    await this.getNews()
  },

  methods: {
    ...mapActions(['getNews', 'getNewsFile', 'getSites']),

    openUrl(siteUrl) {
      window.open(siteUrl, {target: "_blank"});
    },
    show_sidebar() {
      this.$refs.floating_button.style.display = 'none';
      this.$refs.sidebar.style.visibility = 'visible';
      this.$refs.sidebar.style.opacity = '1';
      this.$refs.sidebar.style.padding = '1rem .5rem';
      this.$refs.close_sidebar.style.display = 'block';
    },

    close_sidebar() {
      this.$refs.floating_button.style.display = 'block';
      this.$refs.sidebar.style.visibility = 'hidden';
      this.$refs.sidebar.style.opacity = '0';
      this.$refs.close_sidebar.style.display = 'none';
      this.$refs.close_sidebar.style.visibility = 'hidden';
    }


  },

};
</script>
<style>
p {
  margin-bottom: 0 !important;
}
</style>
