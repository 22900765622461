/* eslint-disable */
import AlertMixin from "@/mixins/alert.mixin";

export default {
    mixins:[AlertMixin],
    methods:{

        async cancelModal(modalName='newsModal'){
            if(await this.cancelAlert()) {
                this.closeModal(modalName)
                return true;
            }

            return false
        },

        closeModal(modalName){
            const modal =  bootstrap.Modal.getInstance(this.$refs[modalName])
            modal.hide();
        },

        showModal(modalName){
            const modal =  bootstrap.Modal.getInstance(this.$refs[modalName])
            modal.show();
        },


        toggleModal(modalName){
            const modal =  bootstrap.Modal.getInstance(this.$refs[modalName])
            modal.toggle();
        },
    }
}