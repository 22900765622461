<template>
  <div class="container-fluid my-4">
    <h1> Sites Lists</h1>
    <div class="row align-content-center-center">
      <div class="col-4">
        <form>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Search</span>
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchParams.search" aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </form>
      </div>
      <div class="col-8 text-end">
        <button type="button" class="mx-2 btn btn-success" @click="setFields({})" data-bs-toggle="modal" data-bs-target="#siteModal">
          Add Site
        </button>
        <button class="btn btn-info"  data-bs-toggle="modal" @click="getSitesToBeSorted()" data-bs-target="#sortModal">Sort Sites</button>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <pagination-component @changePage="setPage" ></pagination-component>
      </div>
    </div>
    <div style="height: 70vh">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col" class="text-center" style="width: 1%">#</th>
          <th scope="col" class="text-center" style="width: 30%">TITLE</th>
          <th scope="col" class="text-center" style="width: 30%">DESCRIPTION</th>
          <th scope="col" class="text-center" style="width: 30%">URL</th>
          <th scope="col" class="text-center" style="width: 15%">CREATED BY</th>
          <th scope="col" class="text-center" style="width: 5%">ACTION</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!sites.length">
          <td colspan="100%" class="text-center text-danger fw-bolder h5 py-2"> --- NO RECORDS FOUND ---</td>
        </tr>
        <tr v-for="(site,index) in sites" :key="index">
          <td>{{pagination.from + index}}</td>
          <td>{{site.title}}</td>
          <td>{{site.description}}</td>
          <td class="text-center"><a :href="site.url" :title="site.title"  target="_blank">{{site.title}}</a></td>
          <td class="text-center">{{site.createdBy}}</td>
          <td class="text-center"><div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Action
            </button>
            <ul class="dropdown-menu">
              <li><button class="dropdown-item" type="button" @click="setFields(site)" data-bs-toggle="modal" data-bs-target="#siteModal">Update</button></li>
<!--              <li><button class="dropdown-item" type="button">Another action</button></li>-->
              <li><button class="dropdown-item" type="button" @click="confirmDeleteSite(site)">Delete</button></li>
            </ul>
          </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="modal fade" id="siteModal" ref="siteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header">
            <button type="button" class="btn-close" ref="closeModalAdd" @click.prevent="cancelModal('siteModal')" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Site Title</span>
                <input type="text" v-model="siteField.title" class="form-control" placeholder="Site Title" aria-label="Site Title" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Site Description</span>
                <input type="text" v-model="siteField.description" class="form-control" placeholder="Site Description" aria-label="Site Description" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Site Url</span>
                <input type="text" v-model="siteField.url" class="form-control" placeholder="Site Url" aria-label="Site Url" aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" @click="saveSite" v-if="!Object.prototype.hasOwnProperty.call(siteField,'id')"  class="btn btn-primary">Save Site</button>
              <button type="button" @click="updateSite" v-else  class="btn btn-primary">Update Site</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="sortModal" ref="sortModal"  tabindex="-1" aria-labelledby="sortModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header">
            <h5 class="modal-title">Sort Sites</h5>
            <button type="button" class="btn-close"  @click.prevent="cancelModal('sortModal')"  aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <draggable-list-component v-model:items="sitesToBeSorted" v-if="sitesToBeSorted" @implSort="updateSort" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {mapActions, mapGetters} from "vuex";
import alertMixin from "@/mixins/alert.mixin";
import DraggableListComponent from "@/components/DraggableListComponent.vue";
import {ErrorService} from "@/services/error.service";
import PaginationComponent from "@/components/PaginationComponent.vue";
import modalMixin from "@/mixins/modal.mixin";
import debounce from "lodash/debounce";
export default {
  name: "SiteComponent",
  components: {PaginationComponent, DraggableListComponent},
  data(){
    return{
      siteField:{},
      searchParams:{
        perPage:10,
        page:1,
        search:"",
      }
    }
  },
  computed:{
    ...mapGetters(['sites','pagination','sitesToBeSorted'])
  },
  mixins:[alertMixin,modalMixin],
  methods:{
    ...mapActions(['getSites','upsertSites','deleteSite','sortSiteLists','getSitesToBeSorted']),
    setFields(site){
      this.siteField=Object.assign({},site);
    },
    async setPage(page = null){
      this.searchParams.page=page ?? 1;
      await this.getSites(this.searchParams)
    },
    async saveSite(){
      try {
        const confirmed = await this.createAlert();
        if(confirmed) {
          const response = await this.upsertSites({params:this.siteField})
          if(response) {
            await this.successAlert('SITE ADDED SUCCESSFULLY','A site has been added successfully')
            await this.setPage(this.pagination.current_page)
            this.setFields({})
            this.closeModal('siteModal')
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'SITE')
        await this.errorAlert(message);
      }
    },
    async updateSite(){
      try {
        const confirmed = await this.updateAlert();
        if(confirmed) {
          const response = await this.upsertSites({params:this.siteField,id:this.siteField.id})
          if(response) {
            await this.successAlert('SITE UPDATED SUCCESSFULLY','A site has been updated successfully')
            await this.setPage(this.pagination.current_page)
            this.setFields({})
            this.closeModal('siteModal')
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'SITE')
        await this.errorAlert(message);
      }
    },
   async  confirmDeleteSite(site){
      try {
        const confirmed = await this.deleteAlert('DELETE SITE','Do you want to remove this site from the list ?');
        if(confirmed) {
          const response = await this.deleteSite(site)
          if(response) {
            await this.successAlert('SITE DELETED','A site has been deleted successfully')
            await this.setPage(this.pagination.current_page)
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'USER')
        await this.errorAlert(message);
      }
    },

    async updateSort(ids){
      try {
        const confirmed = await this.updateAlert('Confirm Sorting','Do you want to save the new sorting list?');
        if(confirmed){
          const params={ids:ids}
          const response = await this.sortSiteLists(params)
          if(response) {
            this.closeModal('sortModal')
            await this.successAlert('SITE SORTED SUCCESSFULLY','Sites list has been sorted successfully')
            await this.setPage(this.pagination.current_page)
          }
        }
      }
      catch (error){
        const message = ErrorService.generateErrorMessage(error,'SITE')
        await this.errorAlert(message);
      }
    }
  },
  async created() {
    this.searchSiteList=debounce(()=>{
      this.setPage()
    },500)
    await this.setPage();
  },
  watch:{
    'searchParams.search'(){
      this.searchSiteList()
    }
  }
}
</script>

<style scoped>

</style>
