import {FileService} from "@/services/file.service";

export default {

    methods:{
       async viewFile(callback,params){
           this.$swal({
              title:'Viewing File..'
           })
           this.$swal.showLoading();
           const obj= Object.assign({},params)
           const response = await callback(obj)
           if(response) {
               const url=FileService.generateFileUrl(response,'TEST','application/pdf')
               FileService.viewFile(url);
               this.$swal.close();
           }
      }
    },

}